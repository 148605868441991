import './App.css';
import Footer from './Components/LandingPage/Footer';
import HeroSection from './Components/LandingPage/HeroSection';
import Navbar from './Components/LandingPage/Navbar';

function App() {
  return (
    <div className="App">
        <Navbar />
        <HeroSection />
      <Footer />
      
    </div>
  );
}

export default App;

import React from "react";
import { Box } from "@material-ui/core";
import "./Footer.css"; // Import your CSS file for styling
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const Footer = () => {
  return (
    <Box className="footer">
       
          
      <Box className="leftEmail">

         
          <h6 style={{color:"#ffff"}}>Connect with us on</h6>
          <Box mr={1}>
         
          <a
          href="https://www.linkedin.com/company/saudisustain"
          style={{
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
              src="/Images/linke.png"
              alt="svg"
              width="30px"
              height="30px"
              margin-right= "6px"

              className="imggg"
            />
            </a>
            </Box>

          {/* <Box className="emailIcon"> */}
          <h6 style={{color:"#ffff"}}>or write to us</h6>
          <Box mr={1}>
         
          <a
          href="mailto:hello@saudisustain.com"
          style={{
            color: "inherit",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            paddingRight:10,
            paddingLeft:10
          }}
        >
          <img
              src="/Images/ff.png"
              alt="svg"
              width="30px"
              height="30px"
              vertical-align = "middle"
              margin-right= "6px"
            />
            </a>
            </Box>
        
            {/* <MailOutlineIcon /> */}
          {/* </Box> */}
          <Box className="emailText">
            <h6>hello@saudisustain.com</h6>
          </Box>
      </Box>

      <Box className="rightText">
        <h6>Copyright © 2024 Saudi Sustainability Technologies</h6>
      </Box>
    </Box>
  );
};

export default Footer;

import React from "react";
import { Grid, Box, Container } from "@material-ui/core";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <Box className="mainSection">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} lg={4} className="leftSection">
          <Box className="leftImage">
            {/* <img
              src="/Images/landingLeft.svg"
              alt="svg"
              width="250px"
              height="150px"
            /> */}
             <video
              // src="https://res.cloudinary.com/dtztsfm99/video/upload/v1719137515/video1_a5o6jw.mp4"
             src="https://res.cloudinary.com/dtztsfm99/video/upload/v1720681604/LoadingPageTopBottomAnimation_thrvuy.mp4"
             className="=logovm"
              autoPlay
              muted
              loop
              preload="auto"
              playsInline
            />
          </Box>
          <Box className="leftText">
            <Box className="item">Stay Tuned!</Box>
            <Box className="item">We Are Building</Box>
            <Box className="item">Something</Box>
            {/* <Box className="item">AWESOME!</Box> */}
            <Box className="leftVideo">
              <video src="https://res.cloudinary.com/dtztsfm99/video/upload/v1719138050/video2_zdg1np.mp4"
               autoPlay
               muted
               loop
               preload="auto"
               playsInline
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} lg={8} className="rightSection">
          <Box className="rightVideo">
            <video
              // src="https://res.cloudinary.com/dtztsfm99/video/upload/v1719137515/video1_a5o6jw.mp4"
             src="https://res.cloudinary.com/dtztsfm99/video/upload/v1720681360/LoadingPage_bkjr11.mp4"
              autoPlay
              muted
              loop
              preload="auto"
              playsInline
            />
          </Box>
        </Grid>
        <Box className="bottomText">
          <h6>Reach us out to know our story or have any suggestions.</h6>
        </Box>
      </Grid>
    </Box>
  );
};

export default HeroSection;

import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-menu">
        <div className="navbar-item">SAUDI</div>
        <div className="navbar-item">SUSTAINABILITY</div>
        <div className="navbar-item">TECHNOLOGIES</div>
      </div>
      <div className="navbar-brand">
        <video
          src="https://res.cloudinary.com/dtztsfm99/video/upload/v1719138185/video3_ip3oov.mp4"
          autoPlay
          muted
          loop
          preload="auto"
          playsInline
        />
      </div>
    </nav>
  );
};

export default Navbar;
